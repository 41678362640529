<template>
  <div id="CentroCustoModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="text-white title-page body-1">Cadastro de Centro de Custo</span>
          <v-btn
            icon
            dark
            color="#F2F6F7"
            @click="fechar()">
            <v-icon large class="title">
              mdi-close
            </v-icon>
          </v-btn>
      </v-card-title>
      <v-card-text class="wrapper pt-1">
        <v-container>
          <v-form 
            class="form-container"
            ref="form"
            v-model="valid">
            <v-row>
              <v-col 
                cols="12"
                class="px-0">
                <v-text-field
                  v-model="dados.centrocusto_descricao"
                  class="mb-0 "
                  light
                  background-color="#FFF"
                  label="Descrição"
                  placeholder="Descrição"
                  filled
                  autofocus
                  clearable
                  :rules="descricaoRules"
                  maxlength="100"
                  outlined
                  required
                  dense />

                  <v-select
                    v-model="dados.ativo"
                    :items="arrayAtivo"
                    no-filter
                    label="Ativo"
                    item-text="name" 
                    item-value="value"
                    filled 
                    outlined 
                    dense 
                    background-color="#FFF" 
                    required/>
                </v-col>
              </v-row>
            </v-form>
        </v-container>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="fechar()"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text>
          Cancelar
        </v-btn>

        <v-btn
          :disabled="!valid"
          :loading="loading"
          @click="validate()"
          class="btn white--text caption font-weight-medium"
          color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import store_site from "../../../store/store_site"
import { API } from '../../../services/API'

import { COR_PRINCIPAL, arraySIM_NAO } from "../../../services/constantes";
import store_Pessoa from "../../../components/Pessoas/store_Pessoa";

export default {
  name: "CentroCustoModal",

  props: ['dados_editar'],

  data() {
    return {
      
      store_site        : store_site,
      store_Pessoa      : store_Pessoa,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      loading           : false,
      search            : null,
      arraySIM_NAO      : arraySIM_NAO,
      dados : {
        centrocusto_descricao  : null,
        ativo                  : 'A'
      },
      valid: true,
      form: false,
      descricaoRules: [
        (value) => !!value || "A Descrição é obrigatória",
      ],
      arrayAtivo : [
        { name: "Sim", value: "A" },
	      { name: "Não", value: "I" },
      ],
    
    };
  },

  mounted(){
    if(this.dados_editar?.acao == 'editar'){
      this.dados.centrocusto_descricao = this.dados_editar.centrocusto_descricao
      this.dados.ativo = this.dados_editar.centrocusto_status
      this.dados.cod_centrocusto = this.dados_editar.cod_centrocusto
    }
  },

  methods: {
    fechar(){
      this.$emit("update:dialog_CentroCustoModal", false);
    },

    async validate () {
      const lb_valido = this.$refs.form.validate();
      // return
      if (lb_valido) {
        this.loading = true

        this.dados.centrocusto_status = this.dados.ativo

        let ls_Rel = null
        // console.log(this.dados);
        // return
        if(this.dados_editar?.acao == 'editar'){
          ls_Rel = await API.put(`/erp/centro_custo/${this.dados.cod_centrocusto}`, JSON.stringify(this.dados));
        }
        else{
          ls_Rel = await API.post(`/erp/centro_custo`, JSON.stringify(this.dados));
        }
        this.loading = false
        if (ls_Rel.status == 200) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Rel.data.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = "#FF0000";
          this.store_site.alert_timeout   = 10000;
          this.store_site.alert_msg       = ls_Rel.data.result.result.trim();
          this.store_site.alert           = true;
        }

        this.fechar()
        this.$emit('busca')
        this.$emit('atualizarCentroCusto', ls_Rel.data.result.data)
      }
    }
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 250px;
  overflow-y: auto;
}

.btn {
  width: 100px;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 
</style>