<template>
  <div id="CarrinhoModal" >
      <!-- Cabeçalho -->
      <v-card-title
        :style="{ 'background-color':COR_PRINCIPAL }"
        class="justify-space-between py-1 pl-3">
        <div>
          <div>
            <span class="title-page">Agendamento/Pagamento em Lote</span>
          </div>
        </div>
        <v-btn @click="fechar()" icon dark color="#F2F6F7">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- Texto com dados da conciliação -->
      <v-card-text :style="{ 'background-color':COR_PRINCIPAL, 'width':'100%!important' }"
                    class="justify-space-between px-0 mb-n1">
        <v-divider dark></v-divider>
        <div class="px-7" :style="{ 'background-color':COR_PRINCIPAL }">

        <v-row class="d-flex justify-space-between">
          <v-col cols="6">
            <v-text-field
              id="txtBuscar"
              ref="txtBuscar"
              autofocus
              prepend-inner-icon="mdi-magnify"
              dense
              autocomplete="off"
              v-model="busca_devedor"
              hide-details=""
              cache-items
              :loading="loading"
              class="search-input"
              clearable
              flat
              dark
              placeholder="Devedor"
              label="Devedor"
              solo-inverted
              tabindex="-1" 
            />
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <!-- Botão Opções ------------------------------- -->
            <v-menu 
              offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="text-white mt-1 mb-n2 mr-2" :color="COR_PRINCIPAL" elevation="0" v-bind="attrs" v-on="on">
                  <v-icon class="mr-1" color="light-blue accent-2">mdi-view-headline</v-icon>
                  <span>Opções</span>
                </v-btn>
              </template>

              <!-- Botões do Opções -->
              <v-list>
                <!-- Botão de Relatórios Automáticos por aplicação -->
                <v-list-item @click=";">
                  <v-menu offset-y
                      expand-icon="mdi-chevron-down"
                      on-icon="mdi-chevron"
                      off-icon="mdi-chevron"
                      indeterminate-icon="mdi-chevron"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title
                          elevation="0"
                          v-bind="attrs"
                          v-on="on"
                          >
                        <v-icon color="gray">mdi-chevron-down</v-icon>
                        <v-icon color="blue" class="mr-2 icon-menu">mdi-printer-outline</v-icon>
                        <span>Relatórios</span>
                      </v-list-item-title>
                    </template>
                    <v-list>
                      <MenuRelatorios
                      :aplicacao_nome="$options.name"/>
                    </v-list>
                  </v-menu>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      </v-card-text>

      <!-- CORPO -->
      <v-card elevation="0" class="corpo-fundo-cor card d-flex d-flex-column">

        <!-- COLUNA ESQUERDA ------------------------------->
        <v-card elevation="0" v-show="expand" style="background-color: transparent; min-width:200px">

          <div class="mb-0 d-flex justify-center">
            <span
              class="font-weight-bold"
              :style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">
              Filtro por Dia
            </span>
          </div>
          <v-card elevation="0" class="d-flex flex-column pb-0" style="background-color: transparent;">
            <v-list :key="key_Data" class="card-list overflow-y-scroll pt-0" dense style="background-color: transparent;">
              <v-list-item-group
                v-model="dataSelecionada"
                active-class="bg-active"
                mandatory
                color="primary">
                <template v-for="i in array_agendados_dia">
                  <v-list-item
                    class="ml-2"
                    :key="i.lote_agendamento_dt"
                    @click="mostrarCobrancasLote(i.lote_agendamento_dt, busca_devedor || '')">
                    <v-list-item-content  class="">
                      <v-badge
                        v-if="i.quant == 0"
                        :value="i.quant"
                        color="green accent-9"
                        offset-x="35"
                        offset-y="18"
                        icon="mdi-check">
                      </v-badge>
                      <v-badge
                        v-else
                        :content="i.quant"
                        :value="i.quant"
                        color="red accent-1"
                        offset-x="35"
                        offset-y="18">
                      </v-badge>
                      <v-list-item-title class="new-font">{{ i.lote_agendamento_dt }}</v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-card>
        <!-- FIM COLUNA ESQUERDA ------------------------------->

        <!-- divisor e botao de expand -->
        <v-card>
          <v-divider class="divisor-vertical" vertical></v-divider>
          <v-btn
            class="btn-expanded ma-0 pa-0"
            :style="`${expand ? '' : 'right: -10px!important;'}`"
            @click="expand = !expand">
            <v-icon
              v-if="expand == false"
              class="size-icon-expanded ma-0 pa-0">mdi-chevron-right
            </v-icon>

            <v-icon
              v-else
              class="size-icon-expanded ma-0 pa-0">mdi-chevron-left
            </v-icon>
          </v-btn>
        </v-card>

        <!-- COLUNA DIREITA ------------------------------->
        <div style="width: 100%; background-color: #FFFFFF;">
          <!-- INICIO TITULOS TABELA EXTRATO ------>
          <table class="px-4" style="width: 100%; background-color: transparent;">
            <tr class="d-flex" style="border-bottom: 1px solid #E0E0E0">
              <td></td>
              <td class="d-flex justify-center" style="width: 10%!important; background-color: transparent;">
                <span class="font-weight-bold" :style="{ 'font-size': '11px', 'color':COR_SUBTITULO }">
                  Incluído por:
                </span>
              </td>
              <td class="d-flex justify-center" style="width: 40%!important; background-color: transparent;">
                <span class="font-weight-bold" :style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">Cobranças</span>
              </td>
              <td class="d-flex justify-center" style="width: 50%!important; background-color: transparent;">
                <span class="font-weight-bold" :style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">Formas de Pagamento</span>
              </td>
            </tr>
          </table>

          <!-- INICIO TABELA EXTRATO -------------------------------------------------------->
          <v-data-table
            :items="itensFiltered"
            :loading="loading"
            :items-per-page="100000"
            fixed-header
            :height="tableHeight"
            dense            
            hide-default-header
            hide-default-footer
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado"
            class="px-4">
            <template #item="{ item }">
              <tr style="" :class="Number(item.empreendcobr_parc_valor) < 0 ? 'custom-highlight-row' : ''">
                <td style="width: 10%;">
                  <v-avatar>
                    <img
                      v-if="item.imagem_caminho_usuario"
                      :src="`${baseURL}${item.imagem_caminho_usuario}`"
                      :title="item.pessoa_nome">
                      <v-icon v-else color="white">mdi-account</v-icon>
                  </v-avatar>
                  <span style="font-size: 11px">
                    {{ item.lote_cesta_data_hora ? formatDate(item.lote_cesta_data_hora) : '' }}
                  </span>
                </td>
                <!-- Cobranças -->
                <td style="width: 45%;">
                  <v-card elevation="0" style="background-color: transparent">
                    <v-row class="pt-0 mt-0 d-flex justify-end">
                      <v-col cols="auto" class="pa-2" >
                        <span :style="{ 'font-size': '13px'}">Devedor/pagador: <strong>{{ item.cobr_dev.pessoa_nome }}</strong></span>
                      </v-col>
                    </v-row>
                    <v-row class="pt-0 mt-0 d-flex justify-end">
                      <v-col cols="auto" class="pa-2" >
                        <span :style="{ 'font-size': '13px'}">Favorecido: <strong>{{ item.cobr_pess.pessoa_nome }}</strong></span>
                      </v-col>
                    </v-row>
                    <v-row class="pt-0 mt-0 d-flex justify-end">
                      <v-col cols="auto" class="pa-2" >
                        <span :style="{ 'font-size': '13px'}">{{ item.empreendcobr_descricao }}</span>
                      </v-col>
                    </v-row>
                    <div>
                      <v-row >
                        <v-col class="mb-n2 mt-0 pa-2 d-flex justify-end">
                          <div>
                            <span :style="{ 'font-size': '14px', 'font-weight': '600'}">
                              <v-icon class="mt-n1" color="#A7A9AA">mdi-calendar</v-icon>
                              {{ formatDate(item.empreendcobr_parc_dt) }}
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="mb-0 mt-n2 pa-2 d-flex justify-end">
                          <div>
                            <span
                              :style="{ 'font-size': '17px', 'font-weight': '600' }">
                              <strong>
                                R$ {{ Number(item.empreendcobr_parc_valor_final) > 0 ? formatPrice(item.empreendcobr_parc_valor_final) : formatPrice(item.empreendcobr_parc_valor_final*-1) }}
                              </strong>
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </td> 

                <!-- Separador -->
                <td :style="item.cobr_pagto.length === 0
                      ? {width: '100px!important', 'background-color': '#FFD9E9', 'border-left': '1px solid #E0E0E0', 'border-right': '1px solid #E0E0E0'}
                      : {width: '100px!important', 'background-color': '#D0FFE0', 'border-left': '1px solid #E0E0E0', 'border-right': '1px solid #E0E0E0'}"
                >
                  <div v-if="item.cobr_pagto.length === 0">
                    <v-icon x-large outlined class="red--text lighten-1"> mdi-arrow-right-bold </v-icon>
                  </div>
                  <div v-if="item.cobr_pagto.length > 0">
                    <v-icon x-large outlined class="green--text lighten-1"> mdi-check-bold </v-icon>
                  </div>
                </td>

                <!-- Formas de pagamento -->
                <td style="width: 45%;">
                  <table style="width: 100%">
                    <tr v-if="item.cobr_pagto.length == 0" class="d-flex justify-space-between">
                      <td class="d-flex " style="align-items:center">
                        <v-tooltip top>
                              <span class="text-none">Clique para pagar esta cobrança </span>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  @click="pagar(item)"
                                  v-on="on"
                                  color="#E9E9E9"
                                  class="background-hover mr-n3 d-flex justify-center"
                                  width="40px"
                                  height="40px"
                                  dense
                                  fab
                                  elevation="0"
                                  outlined>
                                  <v-icon
                                    color="green lighten-1"
                                    dense
                                    dark
                                    >mdi-cash</v-icon>
                                </v-btn>
                              </template>
                            </v-tooltip>
                            <!-- <span class="ml-4">Pagar</span> -->
                        <v-tooltip top>
                          <span class="text-none">
                            Clique para pagar esta cobrança com código de barras 
                          </span>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              @click="pagarCodBarras(item)"
                              v-on="on"
                              color="#E9E9E9"
                              class="background-hover mr-n3 ml-9 d-flex justify-center"
                              width="40px"
                              height="40px"
                              dense
                              fab
                              elevation="0"
                              outlined>
                              <v-icon
                                color="black lighten-1"
                                dense
                                dark
                                >
                                mdi-barcode-scan
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>
                      </td>
                      <td>
                        <!-- <v-btn
                          @click="documentos(dados_editar)"
                          elevation="0"
                          style="background-color: var(--COR_SECUNDARIA)!important;">
                          <v-icon
                            class="btn-icon-plus mr-1 mt-1"
                            color="green accent-4">mdi-badge-account</v-icon>
                        </v-btn> -->

                        <!-- Botão de documentos -->
                        <v-tooltip 
                          top>
                          <span class="text-none">
                            Clique para ver os documentos desta cobrança 
                          </span>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              @click="documentos(item)"
                              v-on="on"
                              color="#E9E9E9"
                              class="background-hover mb-1 mr-n3 ml-9 d-flex justify-center"
                              width="40px"
                              height="40px"
                              dense
                              fab
                              elevation="0"
                              outlined>
                              <v-icon
                                color="green lighten-1"
                                dense
                                dark
                                >
                                mdi-badge-account
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>

                        <!-- Botão para excluir a cobrança do array e do carrinho -->
                        <v-tooltip top>
                          <span class="text-none">
                            Clique para excluir cobrança desta cesta 
                          </span>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              @click="dialog_excluir_item_array = true; item_aux = item"
                              v-on="on"
                              color="#E9E9E9"
                              class="background-hover mr-n3 ml-9 d-flex justify-center"
                              width="40px"
                              height="40px"
                              dense
                              fab
                              elevation="0"
                              outlined>
                              <v-icon
                                color="red lighten-1"
                                dense
                                dark
                                >
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr v-if="item.cobr_pagto.length > 0">
                      <td>
                        <v-card elevation="0" style="background-color: transparent">
                          <v-row class="pt-2 mt-1 d-flex justify-start">
                            <v-col cols="auto">
                              <span style="color:transparent; font-size: 13px">{{ item.empreendcobr_descricao }}</span>
                            </v-col>
                          </v-row>
                          <div>
                            <v-row >
                              <v-col class="mb-n2 mt-0 pa-2 d-flex justify-start">
                                <div>
                                  <span :style="{ 'font-size': '14px', 'font-weight': '600'}">
                                    <v-icon class="mt-n1" color="#A7A9AA">mdi-calendar</v-icon>
                                    {{ formatDate(item.empreendcobr_pagto_dt) }}
                                  </span>
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="mb-n1 mt-0 pa-2 pt-0 d-flex justify-start">
                                <div>
                                  <span
                                    :style="{ 'font-size': '17px', 'font-weight': '600' }">
                                    <strong>
                                      R$ {{ Number(item.empreendcobr_pagto_valor) > 0 ? formatPrice(item.empreendcobr_pagto_valor) : formatPrice(item.empreendcobr_pagto_valor*-1) }}
                                    </strong>
                                  </span>
                                </div>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card>
                      </td>
                    
                      <td style="width: 30%" class="pb-3">
                        <v-row>
                          <v-col class="mb-n2 mt-1 d-flex justify-end">
                            <v-tooltip top>
                              <span class="text-none">Clique para estornar esta cobrança </span>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  @click="dialog_estornar = true; item_estornar_aux = item"
                                  v-on="on"
                                  color="#E9E9E9"
                                  class="background-hover mr-1"
                                  width="40px"
                                  height="40px"
                                  dense
                                  fab
                                  elevation="0"
                                  outlined>
                                  <v-icon
                                    color="red lighten-1"
                                    dense
                                    dark
                                    >mdi-arrow-u-left-top-bold</v-icon>
                                </v-btn>
                              </template>
                            </v-tooltip>
                              <!-- Botão de documentos -->
                        <v-tooltip 
                          top>
                          <span class="text-none">
                            Clique para ver os documentos desta cobrança 
                          </span>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              @click="documentos(item)"
                              v-on="on"
                              color="#E9E9E9"
                              class="background-hover mb-1 mr-1 ml-1 d-flex justify-center"
                              width="40px"
                              height="40px"
                              dense
                              fab
                              elevation="0"
                              outlined>
                              <v-icon
                                color="green lighten-1"
                                dense
                                dark
                                >
                                mdi-badge-account
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>
                        <!-- Botão para excluir a cobrança do array e do carrinho -->
                        <v-tooltip top>
                          <span class="text-none">
                            Clique para excluir cobrança desta cesta 
                          </span>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              @click="dialog_excluir_item_array = true; item_aux = item"
                              v-on="on"
                              color="#E9E9E9"
                              class="background-hover mr-1 ml-1 d-flex justify-center"
                              width="40px"
                              height="40px"
                              dense
                              fab
                              elevation="0"
                              outlined>
                              <v-icon
                                color="red lighten-1"
                                dense
                                dark
                                >
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row v-if="item.empreendcobr_parc_acres_valor">
                          <v-col class="d-flex justify-end mb-0 py-0">
                            <span
                              :style="{ 'font-size': '12px', 'font-weight': '600' }" style="word-break: keep-all">
                              Acréscimo:
                              <strong>
                                R$ {{ Number(item.empreendcobr_parc_acres_valor) > 0 ? formatPrice(item.empreendcobr_parc_acres_valor) : formatPrice(item.empreendcobr_parc_acres_valor*-1) }}
                              </strong>
                            </span>
                          </v-col>
                        </v-row>
                        <v-row v-if="item.empreendcobr_parc_desc_valor">
                          <v-col class="d-flex justify-end mb-0 py-0">
                            <span
                              style="font-size: 12px;font-weight: 600;">
                              Desconto:
                              <strong>
                                R$ {{ Number(item.empreendcobr_parc_desc_valor) > 0 ? formatPrice(item.empreendcobr_parc_desc_valor) : formatPrice(item.empreendcobr_parc_desc_valor*-1) }}
                              </strong>
                            </span>
                          </v-col>
                        </v-row>
                        <v-row v-if="item.empreendcobr_pagto_valor_multa">
                          <v-col class="d-flex justify-end mb-0 py-0">
                            <span
                              :style="{ 'font-size': '12px', 'font-weight': '600' }">
                              Multa:
                              <strong>
                                R$ {{ Number(item.empreendcobr_pagto_valor_multa) > 0 ? formatPrice(item.empreendcobr_pagto_valor_multa) : formatPrice(item.empreendcobr_pagto_valor_multa*-1) }}
                              </strong>
                            </span>
                          </v-col>
                        </v-row>
                        <v-row v-if="item.empreendcobr_pagto_valor_juros">
                          <v-col class="d-flex justify-end mb-0 py-0">
                            <span
                              :style="{ 'font-size': '12px', 'font-weight': '600' }">
                              Juros:
                              <strong>
                                R$ {{ Number(item.empreendcobr_pagto_valor_juros) > 0 ? formatPrice(item.empreendcobr_pagto_valor_juros) : formatPrice(item.empreendcobr_pagto_valor_juros*-1) }}
                              </strong>
                            </span>
                          </v-col>
                        </v-row>
                        <!-- <pre>{{ item }}</pre> -->
                        <v-row v-if="item.empreendcobr_pagto_valor_muljur">
                          <v-col class="d-flex justify-end mb-0 py-0">
                            <span
                              :style="{ 'font-size': '12px', 'font-weight': '600' }">
                              Multa/Juros:
                              <strong>
                                R$ {{ Number(item.empreendcobr_pagto_valor_muljur) > 0 ? formatPrice(item.empreendcobr_pagto_valor_muljur) : formatPrice(item.empreendcobr_pagto_valor_muljur*-1) }}
                              </strong>
                            </span>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                  </table>
                </td> 
              </tr>
            </template>
          </v-data-table>

          <!-- RODAPE TABELA EXTRATO ----------->
          <table class="px-4" style="width: 100%; background-color: transparent">
            <tr class="d-flex" style="border-top: 1px solid #E0E0E0">
              <td class="d-flex justify-center" style="width: 66%!important; background-color: transparent;">
              </td>
              <td
                class="d-flex justify-end"
                :style="{'width': '250px!important', 'background-color': dados.paga_pagar <= 0 ? 'green' : '#FF0000'}">
                <span
                  class="d-flex justify-end"
                  :style="{ 'font-size': '18px', 'font-weight': '600', 'color': '#FFFFFF' }">
                  <strong>
                    R$ {{ valorParaPagar }}
                    <!-- R$ {{ Number(dados.para_pagar) > 0 ? formatPrice(dados.para_pagar): formatPrice(Number(dados.para_pagar)*-1)  }} -->
                  </strong>
                </span>
              </td>
              <td
                class="d-flex justify-center"
                :style="{'width': '130px!important', 'background-color': '#FF0000'}">
              </td>
              <td
                class="d-flex justify-start"
                :style="{'width': '250px!important', 'background-color': '#FF0000'}">
                <span
                  class="d-flex justify-end"
                  v-bind:style="{ 'font-size': '18px', 'font-weight': '600', 'color': '#FFFFFF' }">
                  <strong>
                    R$ {{ valorPago }}
                    <!-- R$ {{ Number(dados.pago) > 0 ? formatPrice(dados.pago) : formatPrice(Number(dados.pago)*-1) }} -->
                  </strong>
                </span>
              </td>
              <td
                class="d-flex justify-center"
                style="width: 50%!important; background-color: transparent;">
                <!-- <span class="font-weight-bold" v-bind:style="{ 'font-size': '15px', 'color':COR_SUBTITULO }">Meus Movimentos</span> -->
              </td>
            </tr>
          </table>
          <!-- FIM RODAPE TABELA EXTRATO --------------->

          <v-footer color="#F2F6F7" elevation="0" class="d-flex justify-center px-0 corpo-fundo-cor">
            <v-btn @click="fechar()" class="mr-4 btn caption font-weight-medium" color="primary" text>
              Cancelar
            </v-btn>
            <v-btn
              @click="pagarLote()"
              :loading="loading"
              :disabled="!btnSalvar"
              class="btn white--text caption font-weight-medium"
              color="primary accent-4">
              Salvar
            </v-btn>
          </v-footer>

        </div>
      </v-card>

    <!-- Dialog Editar/Pagar -->
    <v-container v-if="dialog_novo_editar">
      <v-dialog
        persistent
        v-model="dialog_novo_editar"
        transition="dialog-bottom-transition"
        max-width="800"
        min-width="520">
        <ContasPagarModal
          v-if="dialog_novo_editar"
          :dialog_novo_editar.sync="dialog_novo_editar"
          :dados_editar="dados_editar"
          @atualizaRegistro="atualiza_registro" />
      </v-dialog>
    </v-container>

    <!-- Dialog pagar com cod de barras -->
    <v-container v-if="dialogContasPagarCodigoBarrasModal">
      <v-dialog
        class="my-0"
        v-model="dialogContasPagarCodigoBarrasModal"
        transition="dialog-bottom-transition">
        <ContasPagarCodigoBarras
          :dados_documentos="dados_documentos"
          :array_pagar_cod_barra="array_pagar_cod_barra"
          @fecharModal="dialogContasPagarCodigoBarrasModal = false"
          @atualizaRegistro="atualiza_registro" />
      </v-dialog>
    </v-container>

    <!-- Dialog estornar ----------------------- -->
    <v-container v-if="dialog_estornar">
      <v-dialog
        v-model="dialog_estornar"
        max-width="430"
        elevation="0"
        class="d-flex justify-center">
        <v-divider></v-divider>
        <v-card elevation="0">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3" style="width:100%">
            <span class="white--text title-page body-1">Estornar cobrança</span>
              <v-btn @click="dialog_estornar = false" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja estornar essa cobrança?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_estornar = false; item_estornar_aux = []">
              Cancelar
            </v-btn>

            <v-btn class="btn white--text" color="primary accent-4"  @click="estornar()">
              Estornar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- Dialog excluir item do array ----------------------- -->
    <v-container v-if="dialog_excluir_item_array">
      <v-dialog
        v-model="dialog_excluir_item_array"
        max-width="430"
        elevation="0"
        class="d-flex justify-center">
      <v-divider></v-divider>
    
        <v-card elevation="0">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3" style="width:100%">
            <span class="white--text title-page body-1">Excluir cobrança da cesta</span>
              <v-btn @click="dialog_excluir_item_array = false" icon dark color="#F2F6F7">
                <v-icon large class="title">mdi-close</v-icon>
              </v-btn>
          </v-card-title>
          <v-card-title class="text-h6" style="word-break: keep-all">
            Tem certeza que deseja excluir esta cobrança da cesta?
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir_item_array = false; item_estornar_aux = []">
              Cancelar
            </v-btn>

            <v-btn class="btn white--text" color="primary accent-4"  @click="excluirItem()">
              Excluir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

     <!-- Dialog Documentos -->
     <v-container v-if="dialog_documentos">
      <v-dialog
        persistent
        v-model="dialog_documentos"
        transition="dialog-bottom-transition"
        width="80%">
        <v-card elevation="0" class="pa-0" style="border-radius: 0;">
          <v-card-title 
            :style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between py-1 px-2">
            <span class="title-page text-white">Documentos</span>
            <v-btn @click="dialog_documentos = false" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <GedDocumentos
          :dados_documentos="dados_documentos"
          :cod_empreendcobranca_aux="cod_empreendcobranca_aux"
          :paramsTipoDocumento="paramsTipoDocumento"
          :paramBusca="paramBusca"
          :statusDocumento="statusDocumento"
          @fecharModal="dialog_documentos = false" />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ContasPagarModal from "./ContasPagarModal.vue";
import GedDocumentos from "../../Ged/GedDocumentos.vue";
import store_ContasPagar from "../ContasPagar/store_ContasPagar"
import ContasPagarCodigoBarras from "./ContasPagarCodigoBarras.vue"
import moment from 'moment'
import store_usuario from "../../../store/store_usuario";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SUBTITULO, MASK_CPFCNPJ, MASK_TELEFONE, } from "../../../services/constantes";
import {
  CpfCnpjIsValid,
  telefoneIsValid,
  formatDate,
  maskCpfCnpj,
  formatPrice
} from "../../../services/funcoes";
import store_site from "../../../../src/store/store_site"
import { baseURL, API } from "../../../services/API";
import MenuRelatorios from "../../ComponentesGlobais/MenuRelatorios/MenuRelatorios.vue"

export default {
  name: "CarrinhoModal",

  props: ['dialog_contas_pagar_lote'],

  components: {
    ContasPagarModal,
    ContasPagarCodigoBarras,
    MenuRelatorios,
    GedDocumentos,
  },

  data() {
    return {
      store_site        : store_site,
      store_ContasPagar : store_ContasPagar,
      store_usuario     : store_usuario,
 
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SUBTITULO     : COR_SUBTITULO,  
      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      formatDate        : formatDate,
      maskCpfCnpj       : maskCpfCnpj,
      formatPrice       : formatPrice,

      dialogPagador                      : false,
      dialog_excluir_item_array          : false,
      array_pagar_lote                   : [],
      array_pagar_cod_barra              : [],
      array_agendados_dia                : [],
      baseURL                            : baseURL,
      dialog_novo_editar                 : false,
      cod_empreendcobranca_aux           : null,
      dialogContasPagarCodigoBarrasModal : false,
      item_estornar_aux                  : [],
      dialog_estornar                    : false,
      loading                            : false,
      busca_devedor                      : null,
      array_devedor                      : [],
      item_aux                           : [],
      dialog_documentos                  :false,
      dados                              :{
        para_pagar : null,
        pago       : null
      },
      dataSelecionada                     : null,
      movimentos_dia                      : null,
      valid                               : false,
      form                                : false,
      itensFiltered                       : [],
      key_Data                            : 0,
      expand                              : true,

      data_picker : {
        'min-width':'150px !important',
      },

      notEmptyRule: [value => value?.length > 0  || 'Esse campo é obrigatório'],
      notEmptySelectRule: [ value => !!value || 'Esse campo é obrigatório'],
      numChequeRules: [
        value => !!value || 'Esse campo é obrigatório',
        value => value?.length <= 15 || 'O número não pode ter mais que 15 números.',
      ],
      digitoRules: [ value => value?.length <= 1 || 'Somente 1 dígito' ],
      digitoObrigatorioRules: [ 
        value => !!value || 'Campo obrigatório',
        value => value?.length <= 1 || 'Somente 1 dígito' 
      ],
      agenciaRules : [
        value => !!value || 'Esse campo é obrigatório',
        value => value?.length <= 15 && value?.length > 0 || 'O número não pode ter mais que 15 números.',
      ],
    }
  },

  async created() {
    this.loading = true
    await this.mostrarDatasCobrancasLote()    
    this.loading = false
  },

  computed: {
    tableHeight() {
      return window.innerHeight - 149 - 120;
    },

    btnSalvar(){
      let btnSalvar = true;
      let foundEmptyCobrPagto = false;

      this.array_pagar_lote.forEach(element => {
        if (element.cobr_pagto.length === 0) {
          foundEmptyCobrPagto = true;
          btnSalvar = false;
        }
      });
      return btnSalvar
    },

    valorParaPagar(){
      const somaParaPagar = this.itensFiltered.reduce((total, item) => total + item.empreendcobr_parc_valor_final, 0);
      return formatPrice(somaParaPagar)
    },

    valorPago(){
      const somaPago = this.itensFiltered.reduce((total, item) => total + item.empreendcobr_pagto_valor, 0);
      
      return formatPrice(somaPago)
    }
  },

  watch: {
    busca_devedor(query) {
      this.querySearch(query);
    },
  },

  methods: {
    documentos(item) {
      // this.dados_documentos = {...item}
      // this.cod_empreendcobranca_aux = item.cod_empreendcobranca
      // this.dialog_documentos = true

      this.dados_documentos = {...item}
      this.dados_documentos.tipo = 'CONTAS A PAGAR';
      this.paramsTipoDocumento = {tipo_cadastro: 'cobranca', tipo_cadastro_descricao: "Documentos Pagar/Receber"}
      this.paramBusca = {cod_empreendcobranca : item.cod_empreendcobranca}
      this.statusDocumento = 'Aprovado'
      this.cod_empreendcobranca_aux = item.cod_empreendcobranca
      this.dialog_documentos = true
    },

    // atualizarParaPagar(devedor) {
    //   const somaParaPagar = this.itensFiltered.reduce((total, item) => total + item.empreendcobr_parc_valor_final, 0);
    //   this.dados.para_pagar = somaParaPagar;
    // },

    // atualizarPago(devedor) {
    //   this.dados.pago = somaPago;
    // },

    async estornar(item) {
      var cobranca = this.array_pagar_lote.find(element => element.cod_empreendcobranca == this.item_estornar_aux.cod_empreendcobranca)
      
      cobranca.cobr_pagto                         = []
      cobranca.empreendcobr_situacao              = 'Aberto'
      cobranca.empreendcobr_parc_acres_valor      = null
      cobranca.empreendcobr_parc_desc_valor       = null
      cobranca.empreendcobr_pagto_valor_muljur    = null
      cobranca.empreendcobr_pagto_valor_multa     = null
      cobranca.empreendcobr_pagto_valor_juros     = null
      cobranca.empreendcobr_parc_acres_obs        = null
      cobranca.empreendcobr_parc_desc_obs         = null
      cobranca.empreendcobr_pagto_valor           = null
      cobranca.empreendcobr_pagto_dt              = null
      const dadosFiltrados = cobranca.cob_item.filter(item => item.empreendcobritem_tp_cobr === 'Item');
      cobranca.empreendcobr_parc_valor_final      = dadosFiltrados.reduce((total, item) => total + item.empreendcobritem_valor, 0);
      
      this.dialog_estornar = true

      const response = await API.put(`/erp/contaspagar/contaspagar_estornar/${cobranca.cod_empreendcobranca}`, JSON.stringify(cobranca));
      
      this.dialog_estornar = false

      if (response.status == 200) {
        // Mensagem de estorno confirmado
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = response.data.result.result.trim();
        this.store_site.alert           = true;
      } else {
        // Mensagem de estorno não realizado
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = response.data.errors.trim();
        this.store_site.alert           = true;
      }
    },

    async excluirItem(){
      const resposta = await API.post(`/erp/contaspagar/contaspagar_limpar_cesta`, 
        {cod_empreendcobranca: this.item_aux.cod_empreendcobranca, tipo_mensagem : 'individual'}
      )

      this.dialog_excluir_item_array = false

      if (resposta?.data?.result?.status == 200) {
  
        this.$emit('contadorCarrinho')
        let i = this.array_pagar_lote.findIndex(val => val.cod_empreendcobranca == this.item_aux.cod_empreendcobranca)
        if(i >= 0){
          this.array_pagar_lote.splice(i, 1)
        }
          
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = resposta.data.result.result.trim();
        this.store_site.alert           = true;

        this.mostrarDatasCobrancasLote()
      
      } else {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = resposta.data.result.result.trim();
        this.store_site.alert           = true;
      }
    },
    
    async pagarLote(){
      this.loading = true
   
      this.$emit('contadorCarrinho')
      this.$emit('busca')
      this.fechar()
      this.loading = false
    },
    
    // Função que recebe alterações do filho ContasPagarModal.vue para atualizar a grade
    async atualiza_registro(p_JSON) {
      let lo_Retorno = await this.array_pagar_lote.find((val) => val.cod_empreendcobranca == p_JSON.cod_empreendcobranca);
      if (lo_Retorno) {
        Object.keys(p_JSON).forEach(function (key) {
          lo_Retorno[key] = p_JSON[key];
        });  
      }
    },

    // Função para enviar dados para o filho ContasPagarModal.vue pagamento/baixa 
    async pagar(item) {
      this.dados_editar = this.array_pagar_lote.find(cobranca => cobranca.cod_empreendcobranca === item.cod_empreendcobranca)
      this.dados_editar.acao = 'lote'
      // this.dados_editar.devedor = store_Pessoa.dados.find(({ cod_pessoa }) => cod_pessoa === this.dados_editar.cod_devedor_referencia)
      this.dados_editar.devedor = this.dados_editar.cobr_dev.pessoa_nome
      this.dados_editar.pagador = this.dados_editar.devedor

      this.dialog_novo_editar = true
    },
    
    // Função para pagar com cod de barras
    async pagarCodBarras(item){
      const {data} = await API.get(`/erp/contaspagar_faturas_imagem`,{ params: { cod_empreendcobranca : item.cod_empreendcobranca, tipo_documento : 'Fatura' }});
      this.array_pagar_cod_barra = data.result.data

      if(this.array_pagar_cod_barra.length == 0){
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = 'Não existe fatura para esta cobrança!'
        this.store_site.alert           = true;
        this.loading = false
        return
      }
      this.dados_documentos = item
      this.dialogContasPagarCodigoBarrasModal = true
    },

    // Abre todas as Datas (ESQUERDO)
    async mostrarDatasCobrancasLote() {
      let ls_filtro = this.busca_devedor || '';
      const {data} = await API.get(`/erp/contaspagar_datas_contas_do_carrinho`, { params: {filtro : ls_filtro || ''} });
      if (data.result.length > 0) {
        this.array_agendados_dia = await data.result.map((o) => 
          {
            return {
              lote_agendamento_dt : moment(o.lote_agendamento_dt).format('DD/MM/YYYY'),
              quant               : o.quant,
            }
          }
        );
        await this.mostrarCobrancasLote(this.array_agendados_dia[0].lote_agendamento_dt, this.busca_devedor || '')
        this.dataSelecionada = 0;
        this.key_Data++;
      }
      else {
        this.array_agendados_dia = [];
        this.itensFiltered = []
      }
    },

    // Abre todas as cobrancas da data selecionada (DIREITO)
    async mostrarCobrancasLote(data, filtro) {
      const loRes = await API.get(`/erp/contaspagar_contas_do_carrinho`, { params: { lote_agendamento_dt : data } });
      this.array_pagar_lote = loRes.data.result.data || []

      if(filtro) {
        this.itensFiltered = this.array_pagar_lote.filter(item => item.cobr_dev.pessoa_nome.toUpperCase().indexOf(filtro?.toUpperCase()) > -1)
      } else{
        this.itensFiltered = this.array_pagar_lote
      }

      // funções para fazer calculo
      // this.atualizarParaPagar(this.itensFiltered);
      // this.atualizarPago(this.itensFiltered);
    },

    busca_timeout(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
        await this.mostrarDatasCobrancasLote();
      }, 600);
    },

    querySearch(query){
      let p_params = { params: { filtro: query } }
      this.loading = true
      this.busca_timeout(p_params);
      this.loading = false
    },

    fechar() {
      this.array_pagar_lote = {}
      this.$emit('update:dialog_contas_pagar_lote', false);
    }
  },
};
</script>

<style lang="css" scoped>
.v-data-table__wrapper {
  overflow-y: hidden;
  height: 60vh;
}
.container {
  background: #F2F6F7 !important;
  padding: 0;
  max-width: 100vw;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  font-size: 19px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.title-page-menor {
  font-family: "Open Sans", sans-serif!important;
  font-size: 16px!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

/* .wrapper {
  height: 500px;
   overflow-y: auto; 
} */


.row-value {
  margin-top: -146px;
}

.btn {
  width: 100px;
}

.v-divider {
  background: var(--COR_PRINCIPAL)!important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.bg-active {
  background-color: var(--COR_PRINCIPAL);
  color: white !important;
}
.corpo-fundo-cor {
  background-color: var(--COR_BACKGROUND) !important;
}

.size-icon-expanded {
    font-size: 15px;
  }

  .btn-expanded {
    min-width: 5px !important;
    max-width: 8px !important;
    height: 80px !important;
    position: absolute;
    top: 50%;
    right: -5px;
    z-index: 1;
  }

  .divisor-vertical {
    height: 100% !important;
    width: 1px !important;
    position: relative;
    border-color: #F0F0F0 ;
  }
  
  .title-page {
    font-family: "Montserrat", sans-serif;
    letter-spacing: 0px;
  }

  .text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
  }

</style>

<style scoped lang="scss">

    .input-text {
        ::v-deep {
            .v-text-field {
                input {
                    font-size: 19px;
                }
            }
        }
    }

    ::v-deep .background-hover::before {
      background-color: var(--COR_PRINCIPAL) !important;
      border: 1px solid black;
    }

</style>